<script setup>
import {
  OmnichannelChatSDK,
  isCustomerMessage,
} from '@microsoft/omnichannel-chat-sdk';
import { onMounted, onUnmounted, ref, toRaw, defineProps, computed } from 'vue';
import {
  ChatBubbleOvalLeftIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
  MinusIcon,
  ArrowPathIcon
} from '@heroicons/vue/24/solid';

import Login from './components/Login.vue';
import ChatEnd from './components/ChatEnd.vue';
import Message from './components/Message.vue';
import ChatFooter from './components/ChatFooter.vue';
import messsageSound from './assets/message-received.mp3';
import CustomerType from './components/CustomerType.vue';
import LoadingThingy from './components/LoadingThingy.vue';
import CustomerSurveyForm from './components/CustomerSurveyForm.vue';
import { CHAT_CHANNEL, EVENTS, EXCEPTION_MESSAGE, MESSAGES, REFRESH_INTERVAL } from './constants';
import { addAgentAvailabilityToChannel, departmentMapping, getIdOfQuestionByName, extractDataFromMyAccount, fillingPhone, getCookie, buildDataToStartChat, generateFingerPrint, postChatUserActionsToKafkaTopic, postChatExceptionsToKafkaTopic, getCustomerSession, printJsonMessage, isJsonMessage, saveCustomerSession, getName, getHashKey, decrypt } from './rules'



defineProps({
  isInternal: {
    type: Boolean,
    default: false
  }
})

const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true,
  },
};

const messageNotification = new Audio(messsageSound);
const audioPlayEnabled = ref(false);
const userMuted = ref(false);
const salesLocation = window.location.pathname.includes('sales') ? true : false;
const chatSDK = new OmnichannelChatSDK(omnichannelConfig, chatSDKConfig);
const isLoading = ref(true);
const chatBotTyping = ref(false);
const closedExtra = ref(false);
const showChatWidget = ref(false);
const preChatQuestions = ref([]);
const systemMessage = ref('');
const waiting_to_start = ref(null);
const initializeError = ref(null);
const channelOptions = ref(null);
const preChatSurveyResponses = ref({
  name: '',
  email: '',
  phone: '',
  channel_id: salesLocation ? 'Sales' : '',
  source: window.location.pathname,
  usi: "",
  uuidt: "GUEST",
  uuidr: "GUEST"
});
const isZiplyCustomer = ref(false);
const foundCustomerSession = ref(false);
const isExistingCustomer = ref(false);
const isNewCustomer = ref(false);
const chatMessages = ref([]);
const isTyping = ref(false);
const messageToSend = ref('');
const sendingMessage = ref(false);
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailTranscript = ref('');
const confirmCloseChat = ref(false);
const confirmDecisionState = ref(false);
const showPostChatSurvey = ref(false);
const showQualtricsSurvey = ref(false);
const transcriptSentFailed = ref('');
const transcriptSending = ref(false);
const transcriptSent = ref(false);
const allowChat = ref(true);
const salesAgentsAvailable = ref(true);
const chatId = ref(null);
const labelToIdMap = ref({});
const agentName = ref(null);
const surveyUrl = ref(null);
const offset = ref(0);
const initiatingSurvey = ref(false);
const sendMessageError = ref('');
const offlineTimeout = ref(null);
const inactivityTimeout = ref('');
const convId = ref('');
const currentEnv = import.meta.env.VITE_ENV;
const agentInfoUrl = import.meta.env.VITE_CONVERSATION_AGENT_INFO_URL;
const fingerPrint = ref("");
const hashKey = ref("");
const inputDisabledByAdaptiveCard = ref(false);

// Mobile-App Customization functions
const isMobileApp = localStorage?.getItem('mobile');

const runSetupBeforeChat = async () => {
  const customerData = await getCustomerSession({ uuidf: fingerPrint.value });
  if (customerData.success) {
    foundCustomerSession.value = true;
    try {
      const decryptedCustomerData = decrypt(customerData?.data?.response, hashKey.value);
      if (decryptedCustomerData?.data?.type === "CST") {
        isZiplyCustomer.value = true;
        preChatSurveyResponses.value.channel_id = 'Sales';
      } else {
        isZiplyCustomer.value = false;
        isNewCustomer.value = true;
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    foundCustomerSession.value = false;
    isZiplyCustomer.value = false;
  }
}

const updateQueues = (queues, utc_now, offset) => {
  const _queues = queues?.map((item) => {
    item = addAgentAvailabilityToChannel(item, utc_now, offset);
    // Hide Chat Widget when sales in unavailable and no active chat. 
    if (item.name === "Sales" && salesLocation && !chatSDK?.chatToken?.chatId) {
      salesAgentsAvailable.value = !item.isUnAvailable;
    }
    if (preChatSurveyResponses.value.channel_id === item.name && item.isUnAvailable) {
      preChatSurveyResponses.value.channel_id = '';
    }
    return item;
  });
  return _queues;
};

const loadChannelOptions = async (manualTrigger = false) => {
  const url = import.meta.env.VITE_CHANNEL_OPTIONS_URL;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json();
  if (data?.queues) {
    if (salesLocation && !chatSDK?.chatToken?.chatId && !manualTrigger) {
      const salesChannelDetails = data?.queues?.find((channel) => channel.name === 'Sales');
      const salesChannelAvailability = addAgentAvailabilityToChannel(salesChannelDetails);
      salesAgentsAvailable.value = !salesChannelAvailability?.isUnAvailable;
    };
    const updatedQueues = updateQueues(data?.queues, data?.utc_now, offset.value);
    channelOptions.value = {
      utc_now: data.utc_now,
      queues: updatedQueues,
    }
  } else {
    console.log("Error in fetching channel options");
  }
  return channelOptions.value;
};


const customerChatInterval = setInterval(() => {
  let queues = channelOptions?.value?.queues;
  offset.value = offset.value + REFRESH_INTERVAL;
  const utc_now = channelOptions?.value?.utc_now;
  const updatedQueues = updateQueues(queues, utc_now, offset.value);
  channelOptions.value.queues = updatedQueues;
}, REFRESH_INTERVAL);


onMounted(async () => {
  isLoading.value = true;
  const origin = window.location.origin;
  const hostname = window.location.hostname;
  const currentPath = window.location.pathname;
  fingerPrint.value = await generateFingerPrint();
  if (localStorage?.getItem('mobile')) {
    import('../src/mobile.css');
  }
  try {
    let getChatPathsCall = null
    if (!isMobileApp && hostname !== 'localhost') {
      getChatPathsCall = await fetch(
        `${origin}/helpcenter/GetChatPaths`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
    }

    const getChatPathsResponse = getChatPathsCall ? await getChatPathsCall.json() : {};
    const allowedChatPaths = getChatPathsResponse.allowChatOn ?? [];
    if (isMobileApp) {
      allowChat.value = true;
      console.log(`Chat allowed for my account mobile app`)
    }
    else if (currentPath.includes('/sales')) {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    } else if (currentPath.includes('/myaccount')) {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    }
    else if (allowedChatPaths.length > 0) {
      allowChat.value = allowedChatPaths.some((path) => currentPath === path) || currentPath === '/';
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    }
    else {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    }
  }
  catch (e) {
    console.log(e);
  }

  if (!allowChat.value) {
    isLoading.value = false;
    return;
  }

  await loadChannelOptions();
  await chatSDK.initialize();
  hashKey.value = await getHashKey();
  
  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  const checkPreviousSession = sessionStorage.getItem('MS365ChatSession');
  if (checkPreviousSession) {
    try {
      const optionalParams = {};
      optionalParams.liveChatContext = JSON.parse(checkPreviousSession);
      await chatSDK.startChat(optionalParams);
      chatMessages.value = await chatSDK.getMessages();
      chatMessages.value = chatMessages.value.filter((msg) =>
        msg.content.startsWith("#NDS_WIDGET_ACTION=CITATIONS") ? true :
          msg.content.startsWith("#NDS_WIDGET_ACTION") ? false : true
      );
      await initializeChatEvents();
      showChatWidget.value = true;
      closedExtra.value = true;
      preChatSurveyResponses.value = JSON.parse(
        sessionStorage.getItem('MS365ChatPreChatSurvey')
      );
      const { conversationDetails } = await getConversationData();
      convId.value = conversationDetails.conversationId;
      if (convId.value !== "") {
        try {
          await postChatUserActionsToKafkaTopic(EVENTS.REFRESH, CHAT_CHANNEL.EXTERNAL, channelOptions.value, preChatSurveyResponses.value, convId.value, conversationDetails);
        } catch (e) {
          console.log(e);
        }
      }
      setTimeout(() => {
        document
          .getElementById('scrollContainer')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    } catch (e) {
      try {
        postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.EXTERNAL, preChatSurveyResponses.value, channelOptions.value, e, convId.value, EXCEPTION_MESSAGE.RESTORE_SESSION);
      } catch (e) {
        console.log(e);
      }
      clearSessionStore();
    }
    isLoading.value = false;
    return;
  }
  if (currentPath.includes("/myaccount") || window.origin.includes("ziplyfiber.com")) {
    const { data, refreshToken } = extractDataFromMyAccount();
    if (data) {
      preChatSurveyResponses.value.name = data.name;
      preChatSurveyResponses.value.email = data.email;
      preChatSurveyResponses.value.phone = fillingPhone(data.phone);
      preChatSurveyResponses.value.usi = data?.billingUSI;
      preChatSurveyResponses.value.uuidt = getCookie('Token');
      preChatSurveyResponses.value.uuidr = refreshToken;
      preChatSurveyResponses.value.channel_id = 'Sales';
      const { chatContext, preChatResponse } = buildDataToStartChat(preChatQuestions.value, preChatSurveyResponses.value);
      // If the user is already logged into my account, we will start chat directly from https://ziplyfiber.com and https://ziplyfiber.com/myaccount on any pages inside them.
      startChat(preChatResponse, chatContext);
    }
  } else {
    await runSetupBeforeChat();
  }
  isLoading.value = false;
});

onUnmounted(() => {
  //console.log('called onUnmounted');
  clearInterval(customerChatInterval);
});

const existingCustomerFlow = () => {
  isZiplyCustomer.value = true;
  isExistingCustomer.value = true;
  isNewCustomer.value = false;
}

const newCustomer = () => {
  isExistingCustomer.value = false;
  isZiplyCustomer.value = false;
  isNewCustomer.value = true;
  preChatSurveyResponses.value.channel_id = '';
  preChatSurveyResponses.value.name = '';
  preChatSurveyResponses.value.email = '';
  preChatSurveyResponses.value.phone = '';
  preChatSurveyResponses.value.uuidr = 'GUEST';
  preChatSurveyResponses.value.uuidt = 'GUEST';
}

const toggleMinimise = () => {
  //console.log('Called Toggle Minimise');
  closedExtra.value = !closedExtra.value;
};

// Called when the chat widget is toggled eg: minimise of maximise
const toggleChat = async () => {
  initializeAudio();
  //console.log('Called Toggle Chat');
  if (confirmCloseChat.value) return;

  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    isLoading.value = true;
    closedExtra.value = true;
    //await loadChannelOptions();
    isLoading.value = false;
    if (chatMessages.value.length > 0) {
      setTimeout(() => {
        document
          .getElementById('scrollContainer')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    }
  }
};

const disableChatOnAdaptiveCard = computed(() => {
  if (typeof chatMessages.value[0].content === 'string' && chatMessages.value[0].content.indexOf("attachments") !== -1) {
    const parsedContent = JSON.parse(chatMessages.value[0]?.content)
    return (parsedContent.attachments?.length > 0 && parsedContent.attachments[0].contentType === 'application/vnd.microsoft.card.adaptive')
  }
  return false;
})

const initializeChatEvents = async () => {

  await chatSDK.onNewMessage((message) => {
    chatBotTyping.value = false;
    if (
      chatMessages.value.length === 1 &&
      chatMessages.value[0].id === '00000000-0000-0000-0000-000000000000'
    ) {
      chatMessages.value = [];
      sendingMessage.value = false;
    }
    if (waiting_to_start.value !== null) {
      clearTimeout(waiting_to_start.value);
      waiting_to_start.value = null;
    }
    showChatWidget.value = true;
    if (
      chatMessages.value.length === 1 &&
      message.content === chatMessages.value[0].content
    ) {
      return;
    }
    if (message.content.startsWith("#NDS_WIDGET_ACTION")) {
      const action = message.content.split("=")[1];
      switch (action) {
        case "loading()":
          if (!inputDisabledByAdaptiveCard.value) {
            chatBotTyping.value = true;
          }
          break;
        case "disableInput()": {
          chatBotTyping.value = true;
        }
          break;
        case "enableInput()":
          chatBotTyping.value = false;
          break;
        case "agentEndedSession()":
          sendingMessage.value = true;
          setTimeout(async () => {
            const { conversationDetails, conversationList } = await getConversationData();
            try {
              surveyUrl.value = await buildSurveyUrl(preChatSurveyResponses);
              await postChatUserActionsToKafkaTopic(
                EVENTS.END,
                CHAT_CHANNEL.EXTERNAL,
                channelOptions.value,
                preChatSurveyResponses.value,
                convId.value,
                conversationDetails,
                conversationList,
                "AGENT",
              );
            } catch (e) {
              console.log(e);
            }
            closeChatWindow(true);
          }, 6000);
          break;
        default:
          if (action.startsWith('CITATIONS(')) {
          } else {
            alert("Unknown action: " + action)
            break;
          }
          break;
      }
    }
    if (audioPlayEnabled.value && !message.content.startsWith("#NDS_WIDGET_ACTION")) {
      messageNotification.play();
    }
    chatMessages.value = [message, ...chatMessages.value];
    setTimeout(() => {
      let scrollContainer = document.getElementById('scrollContainer');
      scrollContainer?.scrollTo(0, scrollContainer.scrollHeight)?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    if (!isCustomerMessage(message)) {
      agentName.value = message.sender.displayName;
      chatId.value = chatSDK?.chatToken?.chatId;
    }
  });
  await chatSDK.onTypingEvent(() => {
    isTyping.value = true;
    setTimeout(() => {
      isTyping.value = false;
    }, 1000);
  });
  await chatSDK.onAgentEndSession(async () => {
    //alert("Agent ended the session. Please refresh the page to start a new session.")
    surveyUrl.value = await buildSurveyUrl(preChatSurveyResponses);
    clearSessionStore();
    endChat(true);
  });
  // chatToken.value = await chatSDK.getChatToken();
};



const checkAndSyncMessages = async () => {
  chatMessages.value = await chatSDK.getMessages();
  if (chatMessages.value.length === 0) {
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: 'Connecting...',
        sender: {
          displayName: '',
        },
        timestamp: new Date().toISOString(),
      },
    ];
    sendingMessage.value = true;
    setTimeout(() => {
      document
        .getElementById('scrollContainer')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    waiting_to_start.value = setTimeout(async () => {
      chatMessages.value = await chatSDK.getMessages();
      if (chatMessages.value.length === 0) {
        chatMessages.value = [
          {
            id: '00000000-0000-0000-0000-000000000000',
            content: 'Please wait for an agent to join the chat.',
            sender: {
              displayName: '',
            },
            timestamp: new Date().toISOString(),
          },
        ];
      }
    }, 5000);
  }
};

const chatAction = async () => {
  //console.log('called chatAction');
  console.warn("CHAT INITIATED");
  const checkPreviousSession = sessionStorage.getItem('MS365ChatSession');
  if (salesLocation === true && !showChatWidget.value && !isLoading.value) {
    if (chatSDK.isInitialized && chatSDK?.chatToken?.chatId) {
      toggleChat();
    } else {
      // TODO: For sales we start the chat without prechat questions, check how to proceed with this in chat bots
      // Make the network call again, if sales channel is available and is in operation hours then let the user start chat
      const channel_id = "Sales";
      const _channelOptions = await loadChannelOptions(true);
      const selectedChannel = _channelOptions?.queues.find(
        (item) => item.name === channel_id
      );
      if (selectedChannel?.isUnAvailable) {
        initializeError.value = selectedChannel.message;
        return;
      } else {
        initializeError.value = null;
        startChat();
      }
    }
  } else if (!isLoading.value) {
    toggleChat();
  }
};

// Start Chat on click of start button inside chat or on click of chat icon if in sales page
const startChat = async (preChatResponse, customContext) => {
  // API Call will reset the preChatSurveyResponses.channel_id to '' if the selected channel is unavailable 
  // const channel_id = preChatSurveyResponses.value.channel_id;
  // const _channelOptions = await loadChannelOptions(true);
  // // Setting back preChatSurveyResponses.channel_id to channel_id
  // preChatSurveyResponses.value.channel_id = channel_id;
  // const selectedChannel = _channelOptions?.queues.find(
  //   (item) => item.name === channel_id
  // );
  // if (selectedChannel?.isUnAvailable) {
  //   initializeError.value = selectedChannel.message;
  //   if (salesLocation) {
  //     toggleChat();
  //   }
  //   return;
  // } else {
  //   initializeError.value = null;
  // }
  transcriptSent.value = false;
  // clearInterval(customerChatInterval);
  // offset.value = 0;
  if (!channelOptions.value?.utc_now) {
    return;
  }
  if (isNewCustomer.value) {
    const { firstName, lastName } = getName(preChatSurveyResponses.value.name);
    const session = {
      uuidf: fingerPrint.value,
      firstName,
      lastName,
      email: preChatSurveyResponses.value.email,
      phone: preChatSurveyResponses.value.phone,
      usi: preChatSurveyResponses.value.usi,
    };
    saveCustomerSession(session, hashKey.value);
  }
  if (salesLocation) {
    const sourceKey = getIdOfQuestionByName(preChatQuestions.value, 'Source');
    customContext = {
      ...customContext,
      RequestType: { value: 'Sales' },
    };
    preChatResponse = {
      ...preChatResponse,
      [sourceKey]: window?.location?.href || 'Unknown',
      Type: 'InputSubmit',
    };
  }
  try {
    initializeAudio(true);
    isLoading.value = true;
    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customContext,
      sendDefaultInitContext: true,
    });
    console.warn("CHAT STARTED");
    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem('MS365ChatSession', JSON.stringify(chatContext));
    sessionStorage.setItem(
      'MS365ChatPreChatSurvey',
      JSON.stringify(preChatSurveyResponses.value)
    );
    await initializeChatEvents();
    await checkAndSyncMessages();
    if (salesLocation) {
      showChatWidget.value = !showChatWidget.value;
    }
    setTimeout(async () => {
      const { conversationDetails } = await getConversationData();
      convId.value = conversationDetails.conversationId;
      if (convId.value !== "") {
        await postChatUserActionsToKafkaTopic(EVENTS.START, CHAT_CHANNEL.EXTERNAL, channelOptions.value, preChatSurveyResponses.value, convId.value, conversationDetails);
      }
    }, 10000);
    isLoading.value = false;
    initializeError.value = null;
  } catch (e) {
    try {
      postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.EXTERNAL, preChatSurveyResponses.value, channelOptions.value, e, convId.value, EXCEPTION_MESSAGE.SEND_MESSAGE);
    } catch (e) {
      console.log(e);
    }
    isLoading.value = false;
    if (e.message === 'ConversationInitializationFailure') {
      initializeError.value = 'Error occured while initializing chat. Please try again.';
      window.location.reload();
    } else if (e.message === 'WidgetUseOutsideOperatingHour') {
      initializeError.value = 'Agents are not available at this time. Please try after some time';
    }
    console.log(e);
    if (salesLocation === true) {
      toggleChat();
    }
  }
};

const initializeAudio = (unmute = false) => {
  if (!audioPlayEnabled.value) {
    messageNotification.play().then(() => {
      messageNotification.pause();
      messageNotification.currentTime = 0;
      audioPlayEnabled.value = true;
    });
  }
  if (unmute) {
    userMuted.value = false;
  }
  if (!audioPlayEnabled.value) {
    userMuted.value = true;
  }
};

const sendMessage = async (message, metadata = null) => {
  if (messageToSend.value.length === 0 && message.length === 0) {
    return;
  }
  const _message = message || messageToSend.value;
  initializeAudio(false);
  sendingMessage.value = true;
  try {
    const toSend = metadata ? { content: _message, metadata } : { content: _message };
    await chatSDK.sendMessage(toSend);
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: _message,
        sender: {
          displayName: 'Customer',
        },
        metadata: metadata,
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    messageToSend.value = '';
    setTimeout(() => {
      let scrollContainer = document.getElementById('scrollContainer');
      scrollContainer?.scrollTo(0, scrollContainer.scrollHeight)
      document.getElementById('chatInputBox')?.focus();
    }, 100);
    sendMessageError.value = '';
  }
  catch (e) {
    console.log(e);
    sendMessageError.value = MESSAGES.SEND_MESSAGE_FAILURE;
    sendingMessage.value = false;
  }
};

const customerTyping = async (event) => {
  if (event.key === 'Enter' && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

const getConversationData = async () => {
  let conversationDetails = {};
  let conversationList = [];
  if (chatSDK?.chatToken?.chatId) {
    conversationDetails = await chatSDK.getConversationDetails();
    conversationList = chatMessages.value.map((message) => {
      if (message.sender.displayName === 'Customer') {
        return {
          CUSTOMER: message.content,
        };
      } else {
        return {
          AGENT: message.content,
        };
      }
    })
  }
  return {
    conversationDetails,
    conversationList
  };
}

// Called when close button is clicked when in form screen
const endChat = async (confirmed = false, onInactive = false) => {
  if (convId.value === "") {
    const { conversationDetails } = getConversationData();
    convId.value = conversationDetails.conversationId;
  }
  if (!confirmed) {
    emailTranscript.value = preChatSurveyResponses.value.email ? preChatSurveyResponses.value.email.trim() : '';
    if (chatSDK?.chatToken?.chatId && convId.value !== "") {
      initiatingSurvey.value = true;
      surveyUrl.value = await buildSurveyUrl(preChatSurveyResponses);
      initiatingSurvey.value = false;
    }
  }
  if (onInactive) {
    inactivityTimeout.value = MESSAGES.INACTIVITY_TIMEOUT;
  }
  if (!salesLocation) {
    preChatSurveyResponses.value.channel_id = '';
  }
  if (chatMessages.value.length === 0) {
    showChatWidget.value = false;
    return;
  }
  confirmCloseChat.value = true;
  confirmDecisionState.value = true;
  if (confirmed) {
    emailTranscript.value = preChatSurveyResponses.value.email ? preChatSurveyResponses.value.email.trim() : '';
    showPostChatSurvey.value = true;
  }
};

const cancelEndChat = () => {
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
};

const cleanFieldsAndValidations = () => {
  preChatSurveyResponses.value.name = '';
  preChatSurveyResponses.value.email = '';
  preChatSurveyResponses.value.phone = '';
};

const clearSessionStore = () => {
  sessionStorage.removeItem('timed-out');
  sessionStorage.removeItem('MS365ChatSession');
  sessionStorage.removeItem('MS365ChatPreChatSurvey');
};


const cleanUp = () => {
  if (!isZiplyCustomer.value) {
    clearSessionStore();
    cleanFieldsAndValidations();
  }
  if (foundCustomerSession.value === false && isExistingCustomer.value === true) {
    isExistingCustomer.value = false;
  }
  showChatWidget.value = false;
  closedExtra.value = false;
  chatMessages.value = [];
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
  showPostChatSurvey.value = false;
  chatId.value = null;
  sendMessageError.value = '';
  sendingMessage.value = false;
  offlineTimeout.value = null;
  agentName.value = null;
  surveyUrl.value = null;
  labelToIdMap.value = {};
  initiatingSurvey.value = false;
  clearSurvey();
};

const closeChatWindow = async (endChat = true) => {
  const { conversationDetails, conversationList } = await getConversationData();
  try {
    if (salesLocation) {
      preChatSurveyResponses.value.channel_id = '';
    }
    if (endChat && !transcriptSent.value && chatSDK?.chatToken?.chatId) {
      if (convId.value !== "") {
        try {
          await postChatUserActionsToKafkaTopic(
            EVENTS.END,
            CHAT_CHANNEL.EXTERNAL,
            channelOptions.value,
            preChatSurveyResponses.value,
            convId.value,
            conversationDetails,
            conversationList
          );
        } catch (e) {
          console.log(e);
        } finally {
          await chatSDK.endChat();
          confirmCloseChat.value = true;
          showPostChatSurvey.value = true;
        }
      }
      transcriptSent.value = false;
    } else {
      cleanUp();
      runSetupBeforeChat();
    }
  }
  catch (e) {
    console.log(e);
    try {
      await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.EXTERNAL, preChatSurveyResponses.value, channelOptions.value, e, convId.value, EXCEPTION_MESSAGE.CLOSE_CHAT_WINDOW, conversationDetails);
    } catch (e) {
      console.log(e);
    }
  }
};

const startNewChat = async (endChat = true) => {
  if (salesLocation) {
    preChatSurveyResponses.value.channel_id = 'Sales';
  }
  cleanUp();
  setTimeout(() => {
    salesLocation ? startChat() : toggleChat();
  }, 400);
};

const downloadFile = async (fileMetadata) => {
  chatSDK
    .downloadFileAttachment(toRaw(fileMetadata))
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = fileMetadata.name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    })
    .catch(async (e) => {
      try {
        await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.EXTERNAL, preChatSurveyResponses.value, channelOptions.value, e, convId.value, EXCEPTION_MESSAGE.DOWNLOAD_ATTACHMENT);
      } catch (e) {
        console.log(e);
      }
      // alert('Unable to download attachment.');
    });
};

const processUpload = async (evt) => {
  const file = evt.target.files[0];
  try {
    sendingMessage.value = true;
    const resp = await chatSDK?.uploadFileAttachment(file);
    chatMessages.value = [
      {
        id: resp.id,
        ...resp,
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    evt.target.value = null;
  } catch (e) {
    try {
      await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.EXTERNAL, preChatSurveyResponses.value, channelOptions.value, e, convId.value, EXCEPTION_MESSAGE.UPLOAD_ATTACHMENT);
    } catch (e) {
      console.log(e);
    }
    // alert('Unable to upload file.');
  }
};

const requestEmailTranscript = async () => {
  const { conversationDetails, conversationList } = await getConversationData();
  if (!emailPattern.test(emailTranscript.value)) {
    transcriptSentFailed.value = 'Please enter a valid email.';
    return;
  }
  try {
    transcriptSending.value = true;
    transcriptSentFailed.value = '';
    await chatSDK.emailLiveChatTranscript({
      emailAddress: emailTranscript.value,
      attachmentMessage: 'Your Chat Transcript',
    });
    transcriptSending.value = false;
    transcriptSent.value = true;
    setTimeout(() => {
      transcriptSent.value = false;
    }, 10000);
    try {
      await postChatUserActionsToKafkaTopic(
        EVENTS.TRANSCRIPTS,
        CHAT_CHANNEL.EXTERNAL,
        channelOptions.value,
        preChatSurveyResponses.value,
        convId.value,
        conversationDetails,
      );
    } catch (e) {
      console.log(e);
    } finally {
      try {
        await postChatUserActionsToKafkaTopic(EVENTS.END, CHAT_CHANNEL.EXTERNAL, channelOptions.value, preChatSurveyResponses.value, convId.value, conversationDetails, conversationList);
      } catch (e) {
        console.log(e);
      } finally {
        await chatSDK.endChat();
        confirmCloseChat.value = true;
        showPostChatSurvey.value = true;
      }
    }
  } catch (e) {
    console.log(e);
    try {
      await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.EXTERNAL, preChatSurveyResponses.value, channelOptions.value, e, convId.value, EXCEPTION_MESSAGE.EMAIL_TRANSCRIPT);
    } catch (e) {
      console.log(e);
    }
    transcriptSent.value = false;
    transcriptSending.value = false;
    transcriptSentFailed.value = 'Unable to email transcript.';
  }
};

const emailKeyUp = () => {
  transcriptSentFailed.value = '';
}

//Set this once chatsdk is initialized
window.triggerOmniChat = (secretKey) => {
  if (secretKey === 'D582223DCFE61') {
    showChatWidget.value = true;
    console.warn("CHAT INITIATED");
  }
};

window.hasActiveSession = () => {
  return Boolean(chatSDK?.chatToken?.chatId);
}

const completeLoginFlow = async () => {
  const customerData = await getCustomerSession({ uuidf: fingerPrint.value });
  try {
    const decryptedCustomerData = decrypt(customerData.data.response, hashKey.value);
    preChatSurveyResponses.value.channel_id = 'Sales';
    preChatSurveyResponses.value.name = decryptedCustomerData?.data?.firstName + " " + decryptedCustomerData?.data?.lastName;
    preChatSurveyResponses.value.email = decryptedCustomerData?.data?.email;
    preChatSurveyResponses.value.usi = decryptedCustomerData?.data?.usi;
    preChatSurveyResponses.value.uuidt = decryptedCustomerData?.data?.uuidt;
    preChatSurveyResponses.value.uuidr = decryptedCustomerData?.data?.uuidr;
    preChatSurveyResponses.value.phone = fillingPhone(decryptedCustomerData?.data?.phone);
  } catch (e) {
    console.log(e);
  }
  const { preChatResponse, chatContext } = buildDataToStartChat(preChatQuestions.value, preChatSurveyResponses.value);
  startChat(preChatResponse, chatContext);
}

const fetchAgentDetaials = async (conversationId) => {
  const getAgentDetails = await fetch(agentInfoUrl + '?' + new URLSearchParams({
    "conversation_id": conversationId,
  }), {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await getAgentDetails.json();
}

const buildSurveyUrl = async (preChatSurveyResponses) => {
  const channelId = preChatSurveyResponses.value.channel_id;
  const customerName = preChatSurveyResponses.value.name || "";
  const customerEmail = preChatSurveyResponses.value.email || "";
  const customerPhone = preChatSurveyResponses.value.phone || "";
  let count = 0;
  let retryCount = 2;
  let finalNickName = "";
  let finalCenterName = "";
  let finalCrisId = "";
  let finalConversationId = "";
  let conversationNumber = "";
  const location = departmentMapping.find((dept) => dept.dept === channelId)?.deptId || channelId || "";
  if (chatSDK?.chatToken?.chatId) {
    let agentDetails = null;
    if (convId.value) {
      count = 1;
      agentDetails = await fetchAgentDetaials(convId.value);
    }
    let { nickname, center_name, cris_id, conversation_number } = agentDetails;
    while (cris_id === undefined && count < retryCount) {
      count++;
      agentDetails = await fetchAgentDetaials(convId);
      ({ nickname, center_name, cris_id, conversation_number } = agentDetails)
    }
    finalNickName = nickname ? nickname : "";
    finalCenterName = center_name ? center_name : "";
    finalCrisId = cris_id ? cris_id : "";
    finalConversationId = convId ? convId.value : "";
    conversationNumber = conversation_number ? conversation_number : "";
  }
  return `https://ziplyfiber.sjc1.qualtrics.com/jfe/form/SV_eqXCFSpkf31O68d?location=${location}&TriggeredAt=${new Date().toISOString()}&CustomerName=${customerName}&CustomerEmail=${customerEmail}&CustomerPhone=${customerPhone}&agent_id=${finalCrisId}&ChatID=${finalConversationId}&Contact%20Center%20Name=${finalCenterName}&ConversationNumber=${conversationNumber}&nickname=${finalNickName}&env=${currentEnv}`;
}

const parseCitations = (msg) => {
  return JSON.parse(msg.substring("#NDS_WIDGET_ACTION=CITATIONS(".length, msg.length - 1));
}

const clearSurvey = () => {
  var qualtricsSurvey = document.getElementById('qualtricsSurvey');
  if (qualtricsSurvey) {
    qualtricsSurvey.style.display = 'none';
    if (qualtricsSurvey.childNodes.length > 0) {
      qualtricsSurvey.removeChild(qualtricsSurvey.childNodes[0]);
    }
    showQualtricsSurvey.value = false;
  }
};

if (isMobileApp) {
  toggleChat();
}

window.ononline = async () => {
  if (sessionStorage.getItem('timed-out') === "true") {
    showChatWidget.value = true;
    confirmCloseChat.value = true;
    emailTranscript.value = preChatSurveyResponses.value.email ? preChatSurveyResponses.value.email.trim() : '';
    showPostChatSurvey.value = false;
    endChat(false, true);
    closeChatWindow(true);
  } else if (chatSDK?.chatToken?.chatId) {
    chatMessages.value = await getOldChatMessages();
    try {
      await postChatUserActionsToKafkaTopic(
        EVENTS.REGAINED_CONNECTIVITY,
        CHAT_CHANNEL.EXTERNAL,
        channelOptions.value,
        preChatSurveyResponses.value,
        convId.value
      );
    } catch (e) {
      console.log(e);
    }
    inactivityTimeout.value = '';
    sendingMessage.value = false;
    sendMessageError.value = '';
    clearTimeout(offlineTimeout.value);
    offlineTimeout.value = null;
  }
};

window.onoffline = async () => {
  sendingMessage.value = true;
  sendMessageError.value = MESSAGES.NO_INTERNET;
  offlineTimeout.value = setTimeout(() => {
    sessionStorage.setItem('timed-out', 'true');
  }, SESSION_END_INTERVAL);
};

</script>

<template>
  <!-- Chat Icon Start -->
  <div id="chatWrapper" class="chatWrapper" v-if="salesAgentsAvailable && allowChat">
    <!-- loading spinner for Mobile apps -->
    <div id="mobAppSpinner" v-if="isLoading">
      <div id="loader"></div>
      <p v-if="!isLoading" id="loader-text">
        Please wait while we check for available agents
      </p>
    </div>
    <div id="chatActivator"
      class="!zf-z-[1100] zf-fixed !zf-right-6 !zf-bottom-6 sm:zf-right-12 sm:zf-bottom-12 zf-flex zf-gap-4 zf-items-center">
      <!-- <div
        class="!zf-bg-gray-50 zf-shadow zf-items-center zf-justify-center !zf-px-4 !zf-h-fit !zf-py-2 zf-relative md:zf-flex zf-hidden chat-help"
        v-if="!isLoading && !showChatWidget && !closedExtra">
        <button class="!zf-fill-white !zf-bg-ziply-blue-dark zf-rounded-full zf-absolute -zf-left-2 -zf-top-2 !zf-p-1"
          @click="closedExtra = true">
          <XMarkIcon class="!zf-w-3 !zf-h-3 zf-fill-white" />
        </button>
        <p>Chat With Us</p>
      </div> -->

      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark !zf-bg-ziply-blue md:zf-w-24 md:zf-h-24 md:zf-rounded-[4px]"
        v-if="!showChatWidget" :disaled="isLoading" @click="chatAction()">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <ChatBubbleOvalLeftIcon class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100"
              v-if="!showChatWidget" />
            <p class="!zf-text-zf-gray-200 zf-text-3.5 md:zf-text-xl md:zf-flex zf-hidden zf-m-0 zf-text-white">
              Chat
            </p>
          </div>
        </div>
      </div>
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark zf-circle-button md:zf-w-16 md:zf-h-16 !zf-bg-[#000050]"
        v-if="showChatWidget" :disaled="isLoading" @click="chatAction()">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <Bars3BottomRightIcon class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100" v-if="showChatWidget" />
          </div>
        </div>
      </div>
    </div>
    <!-- Chat Icon End -->
    <div id="chatContainer"
      class="!zf-z-[1100] zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 sm:zf-w-[400px] !sm:zf-h-[546px] !zf-right-6 !zf-bottom-[100px] !sm:zf-right-16 !sm:zf-bottom-[126px] !zf-border !zf-border-white"
      v-if="showChatWidget">
      <div id="mobHeader"
        class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t">
        <MinusIcon class="!zf-h-6 !zf-w-6 !zf-mr-3 !zf-fill-gray-100 zf-cursor-pointer" v-if="!confirmDecisionState"
          @click="toggleChat()" />
        <p class="!zf-text-center !zf-w-full !zf-text-lg zf-text-white zf-m-0 !zf-flex !zf-flex-col">
          <span>Chat with Ziply Fiber</span>
        </p>
        <XMarkIcon id="endChatButton" class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-end-chat-button"
          v-if="!confirmCloseChat && !initiatingSurvey" @click="endChat()" />
        <ArrowPathIcon class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 zf-end-chat-button rotating" v-if="initiatingSurvey">
        </ArrowPathIcon>
        <XMarkIcon id="closeChatButton"
          class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-close-chat-button"
          v-if="showQualtricsSurvey || confirmCloseChat" v-show="showPostChatSurvey" @click="closeChatWindow(true)" />
      </div>
      <!-- Chat Body-->
      <div id="MobConvoBody" class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] !zf-text-justify zf-overflow-hidden">
        <!-- Welcome message and form -->
        <div class="!zf-h-full" id="mobChatBody" v-if="chatMessages?.length === 0">

          <!-- LOADING INDICATOR PAGE -->
          <div v-if="isLoading" class="zf-w-full zf-h-full zf-flex zf-justify-center zf-items-center">
            <LoadingThingy class="!zf-fill-blue-500" />
          </div>

          <!-- LOGIN PAGE -->
          <div v-if="((isZiplyCustomer && foundCustomerSession) || isExistingCustomer)"
            class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center">
            <!-- PASS prechatSurveyResponse as prop -->
            <Login :is-ziply-customer="isZiplyCustomer" :found-customer-session="foundCustomerSession"
              :is-existing-customer="isExistingCustomer" :new-customer="newCustomer"
              :complete-login-flow="completeLoginFlow" :hash-key="hashKey" id="loginFrame" />
          </div>

          <!-- SURVEY FORM PAGE -->
          <div v-if="((!isZiplyCustomer && foundCustomerSession) || isNewCustomer)"
            class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center ">
            <div class="!zf-p-4 !zf-h-full !zf-overflow-scroll zf-no-scrollbar">
              <div class=" !zf-font-bold !zf-mb-1 zf-text-right" @click="existingCustomerFlow">
                <a class="zf-link zf-cursor-pointer">
                  I am existing customer
                </a>
              </div>
              <p class="!zf-mb-8">
                Your privacy is important to us. We will not ask for SSN, or other
                personal information. To pay online, please go to MyAccount (free) or
                use Pay by Phone ($3.50 fee) at <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. If you need
                to cancel
                service, please call <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. To proceed with this
                chat, please
                complete the following and click <strong>Start Chat</strong>.
              </p>
              <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="initializeError">
                {{ initializeError }}
              </p>
              <p v-if="isLoading">Processing...</p>
              <CustomerSurveyForm :channel-options="channelOptions" :startChat="startChat"
                :preChatSurveyResponses="preChatSurveyResponses" :preChatQuestions="preChatQuestions"
                :existing-customer-flow="existingCustomerFlow" :is-loading="isLoading" :is-internal="isInternal"
                v-if="channelOptions" />
            </div>
          </div>

          <!-- CUSTOMER TYPE SCREEN -->
          <div class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center" v-if="!foundCustomerSession">
            <CustomerType :new-customer="newCustomer" :existing-customer-flow="existingCustomerFlow" />
          </div>
        </div>
        <template v-else>
          <div class="!zf-flex zf-flex-col !zf-gap-2 !zf-h-full" v-if="!confirmCloseChat">
            <!-- Chat messages -->
            <div v-if="isMobileApp" id="mobBtnWrapper">
              <p id="mobCloseChatButton" @click="endChat()">END CHAT</p>
            </div>
            <div id="scrollContainer"
              class="!zf-flex !zf-flex-col-reverse !zf-h-[90%] !zf-px-4 !zf-py-2 !zf-overflow-scroll zf-no-scrollbar">
              <div v-for="(message, idx) in chatMessages" :key="message.id" class="!zf-flex !zf-flex-col !zf-gap-1">
                <template v-if="!message.content.startsWith('#NDS_WIDGET_ACTION=')">
                  <!-- BOT MSG -->
                  <div class="!zf-flex !zf-flex-col !zf-items-start !zf-flex-wrap" v-if="!isCustomerMessage(message)">
                    <div class="!zf-text-ziply-blue !zf-flex !zf-justify-center !zf-items-center">
                      <p class="!zf-text-sm !zf-text-ziply-blue !zf-font-normal">
                        {{ message.sender.displayName }}
                      </p>
                    </div>
                    <Message :message="message" :input-disabled-by-adaptive-card="inputDisabledByAdaptiveCard"
                      :next-message="(idx > 0) ? chatMessages[idx - 1] : null" v-if="message.content !== ''"
                      @update:message-on-click="(newValue, metaData) => sendMessage(newValue, metaData)" />
                    <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                      v-if="message.fileMetadata !== undefined">
                      <button class="!zf-text-blue-600 !zf-text-sm zf-underline"
                        @click="downloadFile(Object.freeze(message.fileMetadata))">
                        {{ message.fileMetadata.name }}
                      </button>
                    </div>
                    <div v-if="message.timestamp !== ''">
                      <p class="zf-italic zf-text-xs zf-text-gray-500">
                        {{ new Date(message.timestamp).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                  <!-- Customer Message -->
                  <div class="!zf-flex !zf-flex-col !zf-items-end" v-else>
                    <div class="!zf-text-slate-800 !zf-rounded-full !zf-flex !zf-justify-center !zf-items-center">
                      <p class="!zf-text-sm !zf-font-normal !zf-text-gray-600">
                        {{ preChatSurveyResponses.name }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2" v-if="message.content !== '' &&
                      typeof message.content !== 'object'
                    ">
                      <p class="!zf-text-gray-800 !zf-text-sm !zf-text-right zf-break-words">
                        <span v-if="isJsonMessage(message.content)" v-html="printJsonMessage(message.content)">

                        </span>
                        <template v-else>
                          {{ message.content }}
                        </template>
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                      v-if="message.fileMetadata !== undefined">
                      <button class="!zf-text-blue-600 !zf-text-sm !zf-underline"
                        @click="downloadFile(Object.freeze(message.fileMetadata))">
                        {{ message.fileMetadata.name }}
                      </button>
                    </div>
                    <div v-if="message.timestamp !== ''">
                      <p class="zf-italic zf-text-xs zf-text-gray-500">
                        {{ new Date(message.timestamp).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else-if="message.content.startsWith('#NDS_WIDGET_ACTION=CITATIONS(')" class="mb-2">
                  <div class="!zf-text-ziply-blue !zf-flex">
                    <div class="!zf-flex !zf-flex-col !zf-mt-2 !zf-items-start">
                      <ul class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2 zf-text-left zf-list-none">
                        <li v-for="citation in parseCitations(message.content)" class="rounded border px-2 py-1">
                          <a target="_blank" :href="citation.Url">{{ citation.Id }}. {{ citation.Name }}</a>
                        </li>
                      </ul>
                      <div v-if="message.timestamp !== ''">
                        <p class="zf-italic zf-text-xs zf-text-gray-500">
                          {{ new Date(message.timestamp).toLocaleString() }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>

              </div>
            </div>
            <!-- Chat Messages End-->
            <!-- Chat Footer-->
            <ChatFooter :is-typing="isTyping" :chat-bot-typing="chatBotTyping" :send-message-error="sendMessageError"
              :disable-chat-on-adaptive-card="disableChatOnAdaptiveCard" :system-message="systemMessage"
              :message-to-send="messageToSend" @update:message-to-send="newValue => messageToSend = newValue"
              :process-upload="processUpload" :sending-message="sendingMessage" :send-message="sendMessage"
              :customer-typing="customerTyping" />
          </div>
          <div class="!zf-w-full !zf-h-full !zf-bg-white" v-if="confirmCloseChat">
            <!-- Close Confirmation-->
            <div v-if="!showPostChatSurvey"
              class="zf-text-center zf-flex zf-flex-col zf-items-center zf-justify-center zf-h-full zf-gap-4 zf-px-6">
              <p>Would you like to receive an email transcript of your conversation before you end chat?</p>
              <div class="zf-flex zf-flex-col zf-gap-4 zf-w-full">
                <p v-if="inactivityTimeout" class="zf-text-red-500 zf-text-center zf-text-sm">{{ inactivityTimeout }}
                </p>
                <form method="post" @submit.prevent="requestEmailTranscript()" class="zf-flex zf-flex-col zf-gap-4">
                  <input type="email" :value="emailTranscript" @keyup.prevent="emailKeyUp()"
                    placeholder="Enter your email address" @input="emailTranscript = $event.target.value" :class="{
                      '!zf-border !zf-text-red-700 !zf-border-red-700':
                        transcriptSentFailed !== '',
                    }" id="email_chat_transcript"
                    class="!zf-w-full  !zf-rounded-full !zf-bg-gray-100 !zf-px-4 !zf-py-2" required />
                  <p class="!zf-text-red-500 zf-text-center zf-text-sm" v-if="transcriptSentFailed !== ''">
                    {{ transcriptSentFailed }}
                  </p>
                  <button type="submit"
                    class="!zf-px-6 !zf-py-2 !zf-w-full !zf-rounded-full   zf-bg-ziply-blue !zf-text-md hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6 disabled:zf-bg-gray-500 disabled:zf-text-gray-500 disabled:!zf-cursor-not-allowed"
                    :class="{ 'animate-pulse': transcriptSending }" :disabled="transcriptSending && !transcriptSent">
                    <template v-if="!transcriptSending">End chat and Email Transcript</template>
                    <template v-else>Sending...</template>
                  </button>
                </form>
                <div class="zf-border zf-border-ziply-blue zf-my-4"></div>
                <button
                  class="!zf-px-6 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue !zf-text-md hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                  @click="closeChatWindow(true)">
                  End Chat
                </button>
                <button
                  class="!zf-px-6 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue !zf-text-md hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                  @click="cancelEndChat()">
                  Continue Chatting
                </button>
              </div>
            </div>
            <template v-else>
              <ChatEnd :email-transcript=emailTranscript :show-qualtrics-survey="showQualtricsSurvey"
                @update:show-qualtrics-survey="newValue => showQualtricsSurvey = newValue"
                :inactivity-timeout="inactivityTimeout" :transcript-sent="transcriptSent" :start-new-chat="startNewChat"
                :survey-url="surveyUrl" :is-internal=false :sales-location="salesLocation"
                :sales-agents-available="salesAgentsAvailable" :channel-options="channelOptions"
                :pre-chat-survey-responses="preChatSurveyResponses" :conv-id="convId" :external=true />
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>